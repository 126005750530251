import { Pipe } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate',
  standalone: true,
  pure: false,
})
export class TranslatePipe extends TranslocoPipe implements PipeTransform {}
